import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUploadVideo, { UploadError } from './hooks/use-upload-video';
import { Form, Button, Container, Row, Col, Alert, Spinner, ProgressBar } from 'react-bootstrap';

// Main component
const UploadVideoForm: React.FC = () => {
    const [channel, setChannel] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [unauthorizedError, setUnauthorizedError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [fileValidationError, setFileValidationError] = useState<boolean>(false); // Add this state
    const uploadVideo = useUploadVideo((progress: number) => setProgress(progress));
    const navigate = useNavigate();

    const handleChannelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChannel(event.target.value);
        setError(null);
        setSuccess(null);
        setUnauthorizedError(false);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
            setError(null);
            setSuccess(null);
            setUnauthorizedError(false);
            setFileValidationError(false); // Reset validation error
        }
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!channel || !file || !title || !description) {
            setError('All fields are required.');
            if (!file) {
                setFileValidationError(true); // Set validation error for file input
            }
            return;
        }
        setLoading(true);
        uploadVideo.mutate({ channel, file, title, description }, {
            onSuccess: (data: { videoURL: string; }) => {
                const fullUrl = `${location.origin}/${data.videoURL}`;
                setFile(null); // Clear the file state
                (document.getElementById('formFile') as HTMLInputElement).value = ''; // Reset the file input field
                setLoading(false);
                setSuccess(`Video uploaded successfully. You can view it `);
                setSuccessLink(fullUrl);
            },
            onError: (error: UploadError) => {
                setLoading(false);
                setFile(null); // Clear the file state
                (document.getElementById('formFile') as HTMLInputElement).value = ''; // Reset the file input field
                if (error.response) {
                    if (error.response.status === 401) {
                        setError('Unauthorized: Your session has expired. You will be redirected to the login screen.');
                        setTimeout(() => {
                            navigate('/login?code=sessionTimedOut&callback=upload');
                        }, 2000);
                    } else if (error.response.status === 403) {
                        setUnauthorizedError(true);
                    } else if (error.response.status === 500) {
                        setError('A server error occurred. Please try again later.');
                    } else {
                        console.error('An error occurred:', error.message);
                    }
                } else {
                    console.error('An error occurred:', error.message);
                }
            }
        });
    };

    const [successLink, setSuccessLink] = useState<string | null>(null);

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} lg={10}>
                    <h2>Upload Video</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formChannel">
                            <Form.Label>Channel Handle</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter channel handle"
                                value={channel}
                                onChange={handleChannelChange}
                                isInvalid={!!error && !channel}
                                disabled={loading} // Disable input while loading
                            />
                            <Form.Control.Feedback type="invalid">
                                Channel handle is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter video title"
                                value={title}
                                onChange={handleTitleChange}
                                isInvalid={!!error && !title}
                                disabled={loading} // Disable input while loading
                            />
                            <Form.Control.Feedback type="invalid">
                                Title is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter video description"
                                value={description}
                                onChange={handleDescriptionChange}
                                isInvalid={!!error && !description}
                                disabled={loading} // Disable input while loading
                            />
                            <Form.Control.Feedback type="invalid">
                                Description is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFile">
                            <Form.Label>Video</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".mp4, .mov"
                                onChange={handleFileChange}
                                isInvalid={fileValidationError} // Use validation error state
                                disabled={loading} // Disable input while loading
                            />
                            <Form.Control.Feedback type="invalid">
                                Video file is required.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                                    Uploading...
                                </>
                            ) : (
                                'Upload'
                            )}
                        </Button>
                    </Form>
                    {loading && (
                        <ProgressBar now={progress} label={`${Math.round(progress)}%`} className="mt-3" />
                    )}
                    {loading && (
                        <Alert variant="info" className="mt-3">
                            Uploading Video Please wait...
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="danger" className="mt-3">
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert variant="success" className="mt-3">
                            {success} <a href={successLink ?? '#'} target="_blank" rel="noopener noreferrer">here</a>.
                        </Alert>
                    )}
                    {unauthorizedError && (
                        <Alert variant="danger" className="mt-3">
                            You are not authorized to upload to the specified channel.
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default UploadVideoForm;
