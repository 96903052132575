import React from 'react';
import styles from "./header.module.css";
import headerLogoStyles from '~/header-logo.module.css';
import LoginButton from "../components/login-button";
import {Link} from "react-router-dom";

interface HeaderProps {
    course: {
        title: string;
    };
    channel: string;
}

const Header: React.FC<HeaderProps> = ({channel, course}) => {
    return <header>
        <nav className={styles.nav}>
            <div className={styles.headerWrapper}>
                <Link to={`/channel/${channel}`}>
                    <img className={headerLogoStyles.headerLogo} alt="Feedable" src={`/course-info/${channel}/channel-logo-dark.png`}/>
                </Link>
            </div>
            <LoginButton />
        </nav>
        <h1 className={styles.headerText}>{course.title}</h1>
    </header>;
};

export default Header;