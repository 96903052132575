import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Course from '~/types/course';

interface FetchChildCoursesResponse {
    courses: Course[];
    title: string;
}

const fetchChildCourses = async (params: { channelId: string; courseId: string }): Promise<FetchChildCoursesResponse> => {
    const { channelId, courseId } = params;
    const response = await fetch(`/api/authenticated/courses/child-courses/${channelId}/${courseId}`, { credentials: 'include' });
    if (!response.ok) {
        throw new Error('Failed to fetch child courses');
    }
    const data = await response.json();
    return { courses: data.courses as Course[], title: data.parentTitle };
};

export const useChildCourses = () => {
    const { channelId, courseId } = useParams<{ channelId: string; courseId: string }>();

    return useQuery<FetchChildCoursesResponse | undefined>(
        ['childCourses', channelId, courseId],
        () => {
            if (!channelId || !courseId) return Promise.resolve(undefined);
            return fetchChildCourses({ channelId, courseId });
        },
        {
            enabled: Boolean(channelId && courseId), // Prevents the query from running if params are missing
        }
    );
};
