import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useVideos } from '../hooks/use-videos';
import { ListGroup, Spinner, Alert, Card, Button } from 'react-bootstrap';

interface Video {
    videoId: string;
    url: string;
    title: string;
    description: string;
    ready: boolean;
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const VideoList: React.FC = () => {
    const { channelId } = useParams<{ channelId: string }>();

    if (!channelId) {
        return <Navigate to="/" />;
    }

    const { data: videos, error, isLoading } = useVideos(channelId);
    const [visibleEmbedCodes, setVisibleEmbedCodes] = useState<Set<string>>(new Set());

    const toggleEmbedCode = (videoId: string) => {
        setVisibleEmbedCodes(prevState => {
            const newState = new Set(prevState);
            if (newState.has(videoId)) {
                newState.delete(videoId);
            } else {
                newState.add(videoId);
            }
            return newState;
        });
    };

    if (isLoading) {
        return <div style={{paddingTop: "2em", display: "flex", justifyContent: "center"}}>
            <Spinner animation="border" />
        </div>
    }

    if (error) {
        return <Alert variant="danger">Error fetching videos</Alert>;
    }

    return (
        <div>
            <h2>{capitalizeFirstLetter(channelId)}</h2>
            <ListGroup>
                {videos.map((video: Video) => (
                    <ListGroup.Item key={video.videoId}>
                        <Card className="mt-3">
                            <Card.Body>
                                <Card.Title>
                                    <div>
                                        <a href={`${location.origin}/${video.url}`} target="_blank" rel="noopener noreferrer">
                                            {video.title}
                                        </a>
                                    </div>
                                    <br />
                                    <div>
                                        <a href={`/iframe-test.html?videoId=${video.videoId}&channelId=${channelId}`} target="_blank" rel="noopener noreferrer">
                                            View Inside Iframe
                                        </a>
                                    </div>
                                </Card.Title>
                                <Card.Text>{video.description}</Card.Text>
                                {!video.ready && <Alert variant="warning" style={{ display: 'inline-block' }}>This video is still being processed.</Alert>}
                                <br />
                                <Button variant="primary" onClick={() => toggleEmbedCode(video.videoId)}>
                                    {visibleEmbedCodes.has(video.videoId) ? 'Hide' : 'Show'} Embed Code
                                </Button>
                                <div
                                    className="embed-code-container mt-3"
                                    style={{
                                        maxHeight: visibleEmbedCodes.has(video.videoId) ? 'none' : '0',
                                        visibility: visibleEmbedCodes.has(video.videoId) ? 'visible' : 'hidden',
                                        overflow: 'hidden',
                                        transition: 'visibility 0.3s, max-height 0.3s ease-in-out'
                                    }}
                                >
                                    <Card.Text>
                                        <code>
                                            {`<iframe src="${location.origin}/${video.url}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`}
                                        </code>
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default VideoList;