import {Button} from 'react-bootstrap';

import ResponsiveImage from "../components/responsive-image";
import useUserStatus from "~/hooks/use-user-status";

import styles from "./home.module.css";

export default function Home() {
    const { isAuthenticated } = useUserStatus();
    return (
        <>
                <div className={styles.content}>
                    <div className={styles.leftMain}>
                        <h1 className={styles.heading}>Your <br/>Gateway to Wellness<br/> Mastery</h1>
                        <p className={styles.info}>The most secure streaming platform for Health and Wellness course
                            creators.</p>

                        <section>
                            <h3 style={{color: "red"}}>Explore Channels</h3>

                            <ul className={styles.unstyledList}>
                                <li>
                                    <Button className={styles.channelLogoButton}
                                            variant="outline-primary"
                                            href={isAuthenticated ? '/channel/fiercevoiceacademy' : '/login?callback=channel/fiercevoiceacademy'}>
                                        <img className={styles.channelLogo} alt="Fierce Voice Academy Logo"
                                             src={`/course-info/fiercevoiceacademy/channel-logo-light.png`}/>
                                    </Button>
                                </li>
                                <li>
                                    <Button className={styles.channelLogoButton}
                                            variant="outline-primary"
                                            href={isAuthenticated ? '/channel/hils-art' : '/login?callback=channel/hils-art'}>
                                        <img className={styles.channelLogo} src="/course-info/hils-art/channel-logo-light.png"
                                             alt="Feedable Channel Logo"/>
                                    </Button>
                                </li>
                                <li>
                                    <Button className={styles.channelLogoButton}
                                            variant="outline-primary"
                                            href={isAuthenticated ? '/channel/feedable' : '/login?callback=channel/feedable'}>
                                        <img className={styles.channelLogo} src="/images/feedable-logo-2024-crop.png"
                                             alt="Feedable Channel Logo"/>
                                    </Button>
                                </li>
                            </ul>
                        </section>


                    </div>
                    <div className={styles.rightMain}>
                        <ResponsiveImage alt="Photo of a woman singing in front of a teacher on a TV."
                                         className={styles.mainImage}
                                         basePath="images"
                                         src="sing"/>
                    </div>
                </div>
        </>
    );
}