import React from 'react';
import {Button} from 'react-bootstrap';
import ResponsiveImage from '../components/responsive-image';
import styles from './library-card.module.css';
import Course from "~/types/course";
import LockedResponsiveImage from './locked-responsive-image';

const buttonText: { [key: string]: string } = {
    course: "View Course",
    game: "Play Game",
    default: "View"
};

interface LibraryItemCardProps {
    course: Course;
    linkToSalesPage?: boolean;
    hideLock: boolean
}

function getUrl(params: { course: Course, linkToSalesPage?: boolean }) {
    const {course, linkToSalesPage} = params;
    const {url, channelId, courseId, isParent} = course;

    let libraryUrl = url || `${channelId}/${courseId}`;

    if (!url) {
        if (linkToSalesPage) {
            libraryUrl = `/course/${libraryUrl}`;
        } else {
            if (isParent) {
                libraryUrl = `/channel/${libraryUrl}`;
            } else {
                libraryUrl = course.isEnrolled ? `/player/${libraryUrl}` : `/course/${libraryUrl}`;
            }
        }
    }
    return libraryUrl;
}

export const LibraryCard: React.FC<LibraryItemCardProps> = ({course, linkToSalesPage, hideLock}) => {
    const {type, title, channelId, courseId} = course;
    const libraryUrl = getUrl({course, linkToSalesPage});
    const {isEnrolled, isParent, enrollAll} = course;

    const getImage = () => {
        if ( (!isEnrolled && !hideLock && !isParent && !enrollAll)) {
            return <LockedResponsiveImage
                className={styles.libraryCardImage}
                basePath={`/course-info/${channelId}`}
                src={courseId}
                alt={`Image Title ${title}`}/>
        }

        if (hideLock || isEnrolled || isParent || enrollAll) {
            return   <ResponsiveImage
                className={styles.libraryCardImage}
                basePath={`/course-info/${channelId}`}
                src={courseId}
                alt={`Image Title ${title}`}/>
        }
        return null;
    }

    return (
        <section className={styles.libraryCardItem}>
            <a href={libraryUrl}>
                {getImage()}
            </a>
            <h3>{title}</h3>
            <Button className={styles.libraryCardButton}
                    href={libraryUrl} variant="outline-primary">
                {buttonText[type || 'default']}
            </Button>
        </section>
    );
};

interface LibraryItemListProps {
    courses: Course[];
    linkToSalesPage?: boolean;
    hideLock?: boolean
}

export const LibraryCardList: React.FC<LibraryItemListProps> = ({courses, linkToSalesPage, hideLock = false}) => {
    return (
        <section className={styles.libraryItemsList}>
            {courses.map((course: Course, index: React.Key | null | undefined) => (
                <React.Fragment key={index}>
                    <LibraryCard course={course} linkToSalesPage={linkToSalesPage} hideLock={hideLock}/>
                    {course.relatedCourses && course.relatedCourses.length > 0 && (
                        course.relatedCourses.map((relatedCourse: Course) => (
                            <LibraryCard key={relatedCourse.courseId}
                                         course={relatedCourse}
                                         linkToSalesPage={linkToSalesPage}
                                         hideLock={hideLock} />
                        ))
                    )}
                </React.Fragment>
            ))}
        </section>
    );
};

export default LibraryCard;