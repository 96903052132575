import React from 'react';
import {useParams} from "react-router-dom";

import ResponsiveImage from "~/components/responsive-image";

import styles from './package-bundle.module.css';

const coursesData = [
    {
        src: 'holistic-singer-videos',
        alt: 'Holistic Singer Videos',
        title: 'Holistic Singer Video Course',
        price: '$27.00',
        description: 'Master the foundations of singing by learning the art of relaxation in this beginner video course. With easy-to-follow video demos, relaxing sound meditations, and built-in practice exercises.',
    },
    {
        src: 'holistic-singers-guide-to-breathing',
        alt: 'Holistic Singers Guide to Breathing',
        title: 'Guide to Breathing E-Book',
        price: '$17.00',
        description: 'Breathing guidebook with illustrations, stretches, and breathing workouts to train your body to hold long notes.',
    },
    {
        src: 'holisticsinger-audio-warmups',
        alt: 'Holistic Singer Audio Warmups',
        title: 'Singer Audio Warmup Playlist',
        price: '$12.00',
        description: 'Audio warmup playlist for breathing practice and mastery.',
    },
    {
        src: 'just-breathe',
        alt: 'Just Breathe Single',
        title: '"Just Breathe" Workout Music',
        price: '$5.00',
        description: 'A grounding song and breathwork exercise designed to program proper breathing technique into your body so you don’t have to think about it!',
    },
];

const CourseBundlePage: React.FC = () => {
    const {channelId, courseId} = useParams<{ channelId: string, courseId: string }>();
    return (
        <>
            <h3>THIS PACKAGE INCLUDES:</h3>
            <br/>
            {channelId === 'fiercevoiceacademy' && courseId === 'holisticsinger' &&
                <section className={styles.courses}>
                    {coursesData.map((course, index) => (
                        <div key={index} className={styles.imageContainer}>
                            <ResponsiveImage
                                basePath="/course-info/fiercevoiceacademy"
                                src={course.src}
                                alt={course.alt}
                            />
                            <div>
                                {course.title && <h3>{course.title}</h3>}
                                <div>{course.description}</div>
                                <h3 style={{marginTop: "1rem"}}>
                                    <s>{course.price}</s>&nbsp;<span style={{color: "green"}}>$0.00</span>
                                </h3>
                            </div>
                        </div>
                    ))}
                </section>
            }
        </>
    );
};

export default CourseBundlePage;