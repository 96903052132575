import React from "react";
import { useChannelCatalog } from "~/catalog/hook/use-channel-catalog";
import { LibraryCardList } from "../components/library-card";

const CatalogPage: React.FC = () => {
    const { data: courses, isLoading, error } = useChannelCatalog();

    if (isLoading) {
        return (
            <div style={{ textAlign: "center", marginTop: "5rem" }}>
                Loading...
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: "center", marginTop: "5rem" }}>
                Error loading courses
            </div>
        );
    }

    if (!courses || courses.length === 0) {
        return <h2>Coming Soon</h2>
    }

    return (
        <section>
            <h1>Catalog</h1>
            <LibraryCardList courses={courses} linkToSalesPage={true} hideLock={true}  />
        </section>
    );
};

export default CatalogPage;
