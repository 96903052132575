import React from 'react';
import {useAuthorizedCourses} from './hooks/use-authorized-courses';
import {LibraryCardList} from '../components/library-card';
import {Link} from 'react-router-dom';

const CourseListPage: React.FC = () => {
    const {data: courses, isLoading, error} = useAuthorizedCourses();

    if (isLoading) {
        return <div style={{textAlign: 'center', marginTop: '5rem'}}>Loading...</div>;
    }

    if (error) {
        return <div style={{textAlign: 'center', marginTop: '5rem'}}>Error loading courses</div>;
    }

    if (!courses || courses.length === 0) {
        return (
            <div>
                <p style={{textAlign: 'center', marginTop: '5rem'}}>
                    You have not enrolled in any courses for this channel. Try viewing the{' '}
                    <Link to="/catalog">course catalog</Link>.
                </p>
            </div>
        );
    }

    return (
        <section>
            <h1>My Library</h1>
            <LibraryCardList courses={courses}/>
        </section>
    );
};

export default CourseListPage;
