import React, {useState, useEffect} from 'react';
import {Form, Button, Container, Row, Col, Alert} from 'react-bootstrap';
import {useParams, useNavigate} from 'react-router-dom';

import DOMPurify from 'dompurify';

import useFetchEnrollment from '../hooks/use-fetch-enrollment';
import useFetchCourse from '../hooks/use-fetch-course';
import useCheckoutSession from './hooks/use-checkout-session';
import styles from './course-page.module.css';
import useUserStatus from "../hooks/use-user-status";
import ResponsiveImage from "../components/responsive-image";
import PackageBundlePage from "~/course/package-bundle-page";

const CoursePage: React.FC = () => {
    const {channelId, courseId: courseParam, discountCode} = useParams<{
        channelId: string,
        courseId: string,
        discountCode?: string
    }>();
    const navigate = useNavigate();

    // Ensure parameters are defined
    if (!channelId || !courseParam) {
        return <p>Error: Missing channel or course parameters.</p>;
    }

    const {
        isAuthenticated,
        loading: userLoading,
        error: fetchUserError,
        email
    } = useUserStatus();
    const {isEnrolled, isLoading: enrollmentLoading} = useFetchEnrollment(email, channelId, courseParam);
    const {courseData, loading: courseLoading, fetchCourseError} = useFetchCourse(channelId, courseParam);
    const {mutate: submitEmail, data: sessionData, error: submitError, isLoading: submitLoading} = useCheckoutSession();
    const [emailInput, setEmailInput] = useState<string>('');

    useEffect(() => {
        if (!userLoading && isAuthenticated) {
            setEmailInput(email || "");
        }
    }, [userLoading, isAuthenticated, email]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (isEnrolled) {
            if (!isAuthenticated) {
                navigate('/login');
            } else {
                if (courseData?.isPackage) {
                    window.location.href = `/channel/${channelId}/${courseParam}`;
                } else {
                    window.location.href = `/player/${channelId}/${courseParam}`;
                }
            }
        } else {
            submitEmail({email: emailInput, channel: channelId, course: courseParam, discountCode});
        }
    };

    useEffect(() => {
        if (sessionData) {
            if (sessionData.isAuthorized) {
                setTimeout(() => {
                    window.location.href = `/login?code=alreadyHasAccess&callback=player/${channelId}/${courseParam}`;
                }, 2000);
            } else if (sessionData.sessionUrl) {
                setTimeout(() => {
                    window.location.href = sessionData.sessionUrl;
                }, 2000);
            }
        }
    }, [sessionData, channelId, courseParam]);

    if (courseLoading) {
        return <p>Loading course information...</p>;
    }

    if (fetchCourseError) {
        return <p>Error loading course information: {fetchCourseError.toString()}</p>;
    }

    if (fetchUserError) {
        return <p>Error loading user information: {fetchUserError.toString()}</p>;
    }

    const offerPrice = courseData && discountCode && courseData.promotionPrice ? courseData.promotionPrice : null;
    const normalPrice = courseData && courseData.normalPrice ? courseData.normalPrice : null;
    return (
        <section>
            <Container>
                {courseData && courseData.secondLogo &&
                    <Row>
                        <Col>
                            <ResponsiveImage className={styles.secondLogo}
                                             basePath={`/course-info/${channelId}`}
                                             src={courseData.secondLogo}
                                             alt="Course Second Logo"/>
                        </Col>
                    </Row>

                }

                <Row>
                    <Col md={6}>
                        {courseData &&
                            <ResponsiveImage className={styles.fvaByhoImg}
                                             basePath={`/course-info/${channelId}`}
                                             src={courseParam}
                                             alt="Course Logo"/>
                        }

                    </Col>
                    <Col md={6} className={styles.formWrapper}>
                        {courseData && <h2>{courseData.title}</h2>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formEmail" className={styles.formGroup}>
                                <Row>
                                    <Col xs={8}>
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={emailInput}
                                            onChange={(e) => setEmailInput(e.target.value)}
                                            required
                                            disabled={isAuthenticated}
                                            className={styles.customInput} // Apply custom CSS module class
                                        />
                                    </Col>
                                    <Col xs={4} className="d-flex align-items-end">
                                        <Button variant="primary" type="submit"
                                                disabled={userLoading || enrollmentLoading || submitLoading}>
                                            {isEnrolled ? 'View Course' : 'Checkout'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                        {submitError && <Alert variant="danger" className="mt-3">{submitError.message}</Alert>}
                        {sessionData && sessionData.isAuthorized &&
                            <Alert variant="info" className="mt-3">You are already enrolled please login to access the
                                course...</Alert>}
                        {sessionData && sessionData.sessionUrl &&
                            <Alert variant="info" className="mt-3">Redirecting to secure checkout...</Alert>}


                        {courseData && !courseData.isPackage && (
                            <article className={styles.description}>
                                <div className={styles.offerPrice}>
                                    <div className={styles.normalPrice}>Price
                                        {offerPrice && <span>&nbsp;<s>{courseData.price}</s></span>}
                                        {!offerPrice && <span>&nbsp;{courseData.price}</span>}
                                    </div>
                                    {offerPrice &&
                                        <div className={styles.specialOffer}>
                                            <span>Special Offer </span>
                                            <span className={styles.specialOfferColor}>{offerPrice}</span>
                                        </div>
                                    }
                                </div>
                                <br/><br/>
                                <p className={styles.descriptionText}
                                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(courseData.description)}}/>

                                {courseData.showBundleOffer &&
                                    <section>
                                        <br/>
                                        <h3>Included Free with <a href={`${courseData.bundleOffer.url}`}
                                                                  className={styles.bundleText}>{courseData.bundleOffer.text}</a>
                                        </h3>
                                        <a href={`${courseData.bundleOffer.url}`}>
                                            <ResponsiveImage className={styles.secondLogo}
                                                             basePath={`/course-info/${channelId}`}
                                                             src={courseData.bundleOffer.logoPath}
                                                             alt="Bundle Offer Image"/>
                                        </a>
                                    </section>}

                            </article>

                        )}

                        {courseData && courseData.isPackage &&
                            <section>
                                <article className={styles.description}>
                                    {normalPrice &&
                                        <section className={styles.pricingInfo}>
                                            <div>Normally <s>${normalPrice}</s> <span
                                                className={styles.percentDiscount}>{Math.round((1 - courseData.price / normalPrice) * 100)}% OFF</span>
                                            </div>
                                            {!offerPrice && <div className={styles.packagePrice}>Package Price &nbsp;
                                                <span>${courseData.price.toFixed(2)}</span>
                                            </div>
                                            }
                                            {offerPrice &&
                                                <div>
                                                    <div className={styles.packagePrice}>Package Price &nbsp;
                                                        <s>${courseData.price.toFixed(2)}</s>
                                                    </div>
                                                    <div className={styles.specialOffer}>
                                                        <span>Special Offer </span>
                                                        <span className={styles.specialOfferColor}>{offerPrice}</span>
                                                    </div>
                                                </div>
                                            }
                                            <br/>
                                        </section>
                                    }
                                    <section>
                                        <p className={styles.descriptionText}
                                           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(courseData.description)}}/>
                                        <br/><br/>
                                        <PackageBundlePage/>
                                    </section>
                                </article>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formEmail" className={styles.formGroup}>
                                        <Row>
                                            <Col xs={8}>
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={emailInput}
                                                    onChange={(e) => setEmailInput(e.target.value)}
                                                    required
                                                    disabled={isAuthenticated}
                                                    className={styles.customInput} // Apply custom CSS module class
                                                />
                                            </Col>
                                            <Col xs={4} className="d-flex align-items-end">
                                                <Button variant="primary" type="submit"
                                                        disabled={userLoading || enrollmentLoading || submitLoading}>
                                                    {isEnrolled ? 'View Course' : 'Checkout'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                            </section>
                        }


                    </Col>
                </Row>
            </Container>
        </section>

    );
};

export default CoursePage;