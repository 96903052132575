import { useQuery, UseQueryResult } from 'react-query';
import Courses from '~/types/course';
import { useParams } from 'react-router-dom';

const fetchChannelCatalog = async (channelId: string): Promise<Courses[]> => {
    const response = await fetch(`/api/public/courses/catalog/${channelId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch courses');
    }

    return await response.json();
};

export const useChannelCatalog = (): UseQueryResult<Courses[]> => {
    const { channelId } = useParams<{ channelId: string }>();

    return useQuery<Courses[]>(
        ['courseCatalog', channelId],
        () => {
            if (channelId) {
                return fetchChannelCatalog(channelId);
            } else {
                return Promise.reject(new Error('Channel ID is undefined'));
            }
        },
        {
            enabled: !!channelId, // Only run the query if channelId is defined
        }
    );
};