import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

interface ResponsiveImageProps {
    src: string;
    alt: string;
    basePath: string; // Path to the folder containing the images
    className?: string; // Optional className prop
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ src, alt, basePath, className }) => {
    const [imageError, setImageError] = useState(false);

    const imagePath320 = `${basePath}/${src}/${src}-320.jpg`;
    const imagePath640 = `${basePath}/${src}/${src}-640.jpg`;
    const imagePath1280 = `${basePath}/${src}/${src}-1280.jpg`;
    const imagePath1920 = `${basePath}/${src}/${src}-1920.jpg`;

    if (imageError) {
        return null;
    }

    return (
        <Image
            src={imagePath320}
            srcSet={`
                ${imagePath320} 320w,
                ${imagePath640} 640w,
                ${imagePath1280} 1280w,
                ${imagePath1920} 1920w
            `}
            sizes="
                (max-width: 320px) 280px,
                (max-width: 640px) 440px,
                (max-width: 1280px) 800px,
                1200px
            "
            loading="lazy"
            alt={alt}
            fluid
            className={className} // Apply the optional className
            onError={() => setImageError(true)}
        />
    );
};

export default ResponsiveImage;