import React from 'react';
import { FaLock } from 'react-icons/fa';
import ResponsiveImage from './responsive-image';
import styles from './locked-responsive-image.module.css';

interface CourseImageProps {
    src: string;
    alt: string;
    basePath: string;
    className?: string;
}

const CourseImage: React.FC<CourseImageProps> = ({ src, alt, basePath, className }) => {
    return (
        <div className={styles.lockOverlay}>
            <ResponsiveImage src={src} alt={alt} basePath={basePath} className={className} />
            <FaLock className={styles.lockIcon} />
        </div>
    );
};

export default  CourseImage;