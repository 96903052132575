import React from 'react';
import {Button, Dropdown} from 'react-bootstrap';
import useUserStatus from '../hooks/use-user-status';
import {useAuth} from '../AuthContext';
import styles from './login-button.module.css';

const LoginButton: React.FC = () => {
    const {isAuthenticated, loading, email} = useUserStatus();
    const {login, logout} = useAuth();

    const handleLoginLogout = async () => {
        if (isAuthenticated) {
            await logout();
        } else {
            login();
        }
    };

    return (
        !loading && (
            isAuthenticated ? (
                <Dropdown>
                    <Dropdown.Toggle style={{display: "flex", alignItems:"center"}}
                                     variant="info"
                                     id="dropdown-basic">
                        <span className={styles.email}>{email}</span>  <div className="caret"></div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleLoginLogout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <Button onClick={handleLoginLogout}>
                    Login
                </Button>
            )
        ) || null
    );
};

export default LoginButton;