import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface User {
    id: string;
    name: string;
    email: string;
    // Add other user properties as needed
}

interface AuthContextProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    user: User | null;
    login: () => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch('/api/user', { credentials: 'include' });
                if (response.ok) {
                    const userData = await response.json();
                    setIsAuthenticated(userData.isAuthenticated);
                    setUser(userData.user);
                }
            } catch (e) {
                setIsAuthenticated(false);
                setUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, []);

    const login = () => {
        window.location.href = '/login';
    };

    const logout = async () => {
        await fetch(`${import.meta.env.VITE_API_HOST}/auth/logout/`, {
            method: "post",
            credentials: 'include'
        });
        window.location.href = "/login";
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};